#add-vendor .add-vendor-form .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height:100%;
}

#add-vendor .add-vendor-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;

}

#add-vendor .add-vendor-form .text-area{
    margin-left: 10px;
}

@media  screen and (max-width:800px){
    #add-vendor .add-vendor-form .fields{
        grid-template-columns: 1fr;
    }
}