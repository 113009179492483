#add-material .add-material-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#add-material .add-material-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #add-material .add-material-form .fields {
    grid-template-columns: 1fr;
  }
}

.materialListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}
