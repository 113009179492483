.container-header{
    width: 100%;
    height: 70px;
    background-color: #e9e9e9;
    margin-bottom: 20px;
}
.container-header .form-name{
    position: relative;
    height: 100%;
}
.container-header .form-name h2{
    margin-top: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.container-header .breadcrum{
 float: right;  
 margin-right: 20px;

}
.container-header .button-crums .buttons{
    display: flex;
    flex-direction: row;
}
.container-header .button-crums .buttons .refresh{
    display: none;
}
.container-header .button-crums {
    left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width:750px){
    .container-header{
      margin-bottom: 30px;
    }
    .container-header .breadcrum{
        display: none;
     }
    .container-header .button-crums .buttons{
        float: top;
        padding-bottom: 5px;
        display: flex;
    }
    .container-header .button-crums .buttons .refresh{
        display: block;
    }
}