.modal {
  position: fixed !important;
  z-index: 500;
  background-color: white;
  width: 300px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 39%;
  top: 40%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (max-width: 600px) {
  .modal {
    left: 30px;
  }
}
