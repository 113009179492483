@media screen and (max-width: 1000px) {
  .job-complete-res {
    display: grid;
    grid-template-columns: 1fr;
  }
  .jc-col {
    width: 90%;
    margin-left: 5%;
  }
  .sc-button Button {
    margin-top: 4vh;
  }
  .job-certificate-edit {
    margin-top: 4vh;
  }
}
