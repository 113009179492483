@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap");
.pd-bg {
  display: flex;
  flex-direction: column;
  background: rgb(234, 240, 250);
  width: 100%;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}
.pd-sec1 {
  margin: 1%;
  width: 98%;
  min-height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.pd-sec1-in {
  width: 48%;
  min-height: 30vh;
}
.pd-sec2 {
  margin: 1%;
  width: 92%;
  align-self: center;
  color: rgb(8, 22, 59);
  padding: 0 2%;
  min-height: 20vh;
  padding-bottom: 2.5vh;
  background: rgb(217, 230, 248);
}
.pd-sec2 p {
  font-weight: 600;
}
.pd-button {
  width: 30%;
  align-self: center;
  height: 6vh;
  background: rgb(14, 70, 134);
  border: none;
  color: white;
  margin: 3vh;
  margin-bottom: 6vh;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
}
.pd-sec3-in {
  /* background:rgb(42, 91, 148); */
  background: rgb(85, 146, 214);
  margin: 1vh;
  display: flex;
  align-self: center;
  align-items: center;
  width: 94%;
  padding: 0 2%;
  height: 6vh;
  border-radius: 3px;
  flex-grow: 1;
  color: white;
}
.pd-sec3-in2 {
  margin: 1vh;
  display: flex;
  align-self: center;
  width: 94%;
  padding: 2%;
  border-radius: 3px;
  color: rgb(8, 22, 59);
  min-height: 23vh;
  background: white;
  position: relative;
}

.pd-sec3-in2 a {
  text-decoration: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.material-list {
  margin: 0.5%;
  padding: 0.5%;
  box-sizing: border-box;
}

.drawing-details {
  margin: 1vh;
  display: flex;
  align-self: center;
  width: 94%;
  padding: 2%;
  border-radius: 3px;
  color: rgb(8, 22, 59);
  min-height: 23vh;
  background: white;
  position: relative;
}

.drawing-details .drawing-grid {
  width: 100%;
  padding: 1.5%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 1vh;
}
@media screen and (max-width: 1000px) {
  .pd-sec1 {
    flex-direction: column;
  }
  .pd-sec1-in {
    width: 99%;
    align-self: center;
    margin: 0.5vh 0;
  }
  .pd-button {
    width: 50%;
  }
}
