.ims-entry-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 20px;
}

@media only screen and (max-width: 800px) {
  .ims-entry-form {
    grid-template-columns: 1fr;
  }
}
