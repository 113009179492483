.pages {
  display: inline-block;
}

.pages a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pages a.active {
  background-color: #4caf50;
  color: white;
}

.pages a:hover:not(.active) {
  background-color: #ddd;
}
