#add-user .add-user-form .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}
#add-user{
    overflow-x:hidden;
}

#add-user .add-user-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
.au-areascontainer{
    width:94%;
    margin:2vh;
    background:rgb(219, 219, 219);
    border-radius:4px;
    display:flex;
    flex-direction:row;
    padding:0.5vh;
    justify-content:flex-start;
    flex-wrap:wrap;
    min-height:2vh;
}
.au-zones{
    margin:2vh 2%;
    width:96%;
    min-height:5vh;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}
.au-allareas{
    width:30%;
    margin:1.5vh 1%;
    background:rgb(255, 255, 255);
    border-radius:4px;
    color:black;
    display:flex;
    flex-direction:column;
    min-height:10vh;
}
.au-area-head{
    width:98%;
    min-height:2.5vh;
    padding: 1%;
    background:#25a25a;
    color:white;
}
.au-text{
    margin:2vh 1%;
}
@media  screen and (max-width:850px){
    #add-user .add-user-form .fields{
        grid-template-columns: 1fr
    }
    .au-allareas{
        width:48%;
    }
}
@media  screen and (max-width:600px){
    .au-allareas{
        width:98%;
    }
}
