.userList .userListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}
.zone-seperate {
  background:#25a25a;
  padding: 0.5vh;
  margin: 0 0.5vh;
}

.user-zones {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-zones .aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 13px;
  box-sizing: border-box;
}
.minHight-ul {
  min-width: 150px;
}
