#user-jobs .user-jobs-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
}

#user-jobs .user-jobs-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #user-jobs .user-jobs-form .fields {
    grid-template-columns: 1fr;
  }
}
