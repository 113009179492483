#navbar {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 50px calc(100% - 50px);
  grid-template-columns: 240px calc(100% - 240px);
}

#navbar div {
  position: relative;
}
#navbar div .nav-logo-container {
  position: fixed;
  height: 50px;
  width: 240px;
}
#navbar div #nav-logo {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#navbar div #nav-logo img {
  margin-left: 20px;
}
#navbar div #top-nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #2c3e50;
  z-index: 1000;
}

#navbar div #top-nav img {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  color: white;
}

#navbar div #top-nav .button .img {
  margin-top: 5px;
  color: white;
  font-weight: 400;
}
#navbar div #top-nav .button {
  position: absolute;
  right: 30px;
  top: 50%;
  height: 30px;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 10px;
}

#navbar div .side-nav-container {
  position: fixed;
  width: 240px;
  height: 100%;
  overflow-y: scroll;
}

#navbar div .side-nav-container::-webkit-scrollbar {
  width: 4px;
}
#navbar div .side-nav-container::-webkit-scrollbar-track {
  background-color: rgba(60, 60, 60, 0.4);
  border-radius: 3px;
}
#navbar div .side-nav-container::-webkit-scrollbar-thumb {
  background-color: #1abc9c;
  border-radius: 3px;
}

#navbar div #side-nav {
  height: 140%;
  width: 100%;
  background-color: #2c3e50;
}

#navbar div #side-nav .side-nav-usr {
  width: 100%;
  margin: 0 auto;
  height: 100px;
  padding: 5px;
  box-sizing: border-box;
}

#navbar div #side-nav .side-nav-usr .user {
  background-color: #3e5771;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border-top: 3px solid #1abc9c;
  display: grid;
  grid-template-columns: 30% 70%;
}

#navbar div #side-nav .side-nav-usr .user p {
  margin: 4px;
  color: white;
}

#navbar div #side-nav .nav-element {
  cursor: pointer;
  height: 40px;
  width: 100%;
  background-color: #2e2e2e;
  color: white;
  position: relative;
  overflow: hidden;
}
#navbar div #side-nav .nav-element .selected-nav-el {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #e9e9e9;
  z-index: 100;
  transform: translate(50%, -50%) rotate(45deg);
}
#navbar div #side-nav .nav-element .nav-element-content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
#navbar div #side-nav .nav-element .nav-element-content p {
  color: white;
}
#navbar div #side-nav .nav-element .nav-element-content img {
  margin-right: 10px;
  margin-top: 4px;
}

#navbar #body {
  box-sizing: content-box;
  background-color: #e9e9e9;
}
