:root {
  --primary-color: #3498db;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #e74c3c;
  --success-color: #2ecc71;
}

button {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 5px;
}

.link {
  background: none;
  padding: 0;
  margin: 0;
}

.block {
  display: block;
  width: 100%;
}

.sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.primary {
  background: var(--primary-color);
  color: #fff;
}

.light:hover {
  color: #fff;
  background: #24292e;
}

.light {
  background: var(--light-color);
  color: #333;
}

.dark {
  background: var(--dark-color);
  color: #fff;
}

.danger {
  background: var(--danger-color);
  color: #fff;
}

.success {
  background: var(--success-color);
  color: #fff;
}

.white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}
