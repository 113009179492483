.vendorList .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.fields{
  margin-bottom:3vh;
}


.vendorList .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.vendorList .vendorListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

.vendorList .updateButton {
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .vendorList{
    overflow-x:hidden;
  }
  .vendorList .fields {
    grid-template-columns: 1fr;
  }
}
