.add-godown .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}

.add-godown-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}