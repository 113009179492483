input,
textarea,
select {
  height: 40px;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 3px;
  font-size: 12px;
  padding: 0 1vh;
  width: 15%;
  margin: 10px 0px 20px 0px;
  box-sizing: border-box;
}

input[type="file"] {
  height: 30px;
  border: none;
  margin: 10px 0px 20px -5px;
  font-size: 12px;
  width: 100%;
  padding: 0 1vh;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* border: solid 2px rgb(28, 192, 137); */
  box-shadow: 0px 0px 3px inset rgba(0, 0, 0, 0.3);
}

.wide {
  width: 95%;
}

textarea {
  box-sizing: unset;
}
