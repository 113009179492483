
#change-password .fields{
   display: flex;
   flex-direction: row;
}

#change-password .fields .field{
    
    padding-right: 30px;
    padding-bottom: 30px;
}

@media screen and (max-width: 600px){

#change-password .fields{
    flex-direction: column;
 }
}