.login-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: rgb(70, 70, 70);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgb(230, 31, 0) 52%,
    rgb(230, 31, 0) 50%,
    rgb(230, 31, 0) 100%
  );
  align-items: center;
  justify-content: center;
}

.project-title {
  background-color: white;
  border-top: 3px solid #e61f00;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.project-title h1 {
  margin: 20px;
  color: white;
  background-color: deepskyblue;
}
.login-card {
  padding: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 3px solid #e61f00;
  border-radius: 3px;
  width: 350px;
  min-height: 380px;
  background-color: white;
  padding: 10px;
}
.login-logo {
  width: 190px;
  margin-top: 3vh;
  height: auto;
  padding-bottom: 2vh;
}
.login-input-container {
  width: 90%;
  padding-top: 2vh;
  height: 100%;
  border-top: solid 1px rgba(207, 207, 207, 0.664);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 13px;
}
.login-input-container span {
  text-align: left;
}
.login-forgot {
  text-decoration: none;
  font-weight: 700;
  color: rgb(70, 70, 70);
  font-size: 12px;
  /* margin-top:0.2vh; */
}
.login-signin {
  background: rgb(255, 54, 54);
  border: solid 2px rgb(255, 54, 54);
  margin-top: 2vh;
  width: 98%;
  border-radius: 3px;
  font-weight: 500;
  height: 5vh;
  color: white;
  font-size: 2vh;
}
.login-ipt {
  height: 30px;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 3px;
  font-size: 12px;
  padding: 0 1vh;
  width: 94%;
}
.login-ipt:focus {
  outline: none;
  border: solid 2px rgb(28, 192, 137);
}
@media screen and (max-width: 350px) {
  .login-card {
    width: 290px;
    height: 370px;
  }
}
