.materialIssue .materialForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.materialIssue .materialForm .formField {
  padding: 0 10px;
  box-sizing: border-box;
}

.addMaterialForm {
  margin-top: 2vh;
}

.materialIssue .addMaterialForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.materialIssue .addMaterialForm .field {
  padding: 0 10px;
  box-sizing: border-box;
}

.materialIssue .materialTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

@media screen and (max-width: 800px) {
  .materialIssue .materialForm {
    grid-template-columns: 1fr;
  }
  .materialIssue .addMaterialForm {
    grid-template-columns: 1fr;
  }
}
