.sortedTable,
th,
td {
  table-layout: auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding: 15px;
}
.sortedTable-header {
  background-color: #2c2c2c;
  color: white;
  border-right: 1px solid white;
  cursor: pointer;
}

.sortedTable .redRow {
  background-color: salmon;
}

.sortedTable .greenRow {
  background-color: green;
}

.sortedTable .grayRow {
  background-color: #25a25a;
}
.pagination .fields {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  height: 100%;
}

.pagination .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .pagination .fields {
    grid-template-columns: 1fr;
  }
}
