.white-card-parent {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.white-card {
  border-top: 5px solid #1abc9c;
  padding: 20px;
  padding-top: 40px;
  box-shadow: 0px 0px 0px rgba(203, 112, 255, 0.521);
  border-radius: 4px;
  background-color: white;
  min-height: 50vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .white-card-parent {
    padding: 5px;
  }

  .white-card {
    padding: 10px;
    padding-top: 20px;
  }
}
