.wo-static{
    width:98%;
    margin-bottom:2vh;
    padding:2vh 1%;
    min-height:0vh;
}
.wo-center{
    display:flex;
    width:50%;
    height:5vh;
    margin:0 25%;
    align-items:center;
    justify-content:center;
}
#wo-border{
    border-top: solid 1px rgba(187, 187, 187, 0.568);
}
@media screen and (max-width:1000px){
    .wo-space{
     height:10vh;
     width:100%;
    }
    

}
