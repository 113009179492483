#reports{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}

#reports a{
    color: unset;
    text-decoration: unset;
}
#reports .report{
    min-height: 70px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(60,60,60,0.4);
    transition-duration: 0.4s;
    padding: 15px;
    box-sizing: border-box;
    border-left: 3px solid #1abc9c;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

#reports .report:hover{
    box-shadow: 0 0 10px rgba(60,60,60,0.2);
}

#reports .report h3{
    margin: 0;
    font-weight: 400;
}

#reports .report P{
    font-size: 0.9rem;
}

@media screen and (max-width:900px){
    #reports{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}
@media screen and (max-width:730px){
    #reports{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }   
}
@media screen and (max-width:450px){
    #reports{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
}