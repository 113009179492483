#an-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  min-height: 30vh;
}
.an-row {
  display: flex;
  align-items: center;
}
.an-row-2 {
  display: flex;
  padding-top: 3vh;
  border-top: solid 1px rgba(185, 185, 185, 0.548);
  align-items: center;
}
.an-row-in {
  width: 100%;
  margin: 0 2vh;
}
@media screen and (max-width: 1000px) {
  .an-row {
    display: flex;
    flex-direction: column;
  }
}
