input[type="radio"],
input[type="checkbox"] {
  height: auto;
  width: auto;
  margin: 10px 10px 20px 0px;
  font-size: 12px;
}

label {
  margin-right: 20px;
  padding: 5px;
  vertical-align: baseline;
}
