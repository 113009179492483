#project-list {
}

#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}
