.unsortedTable,
th,
td {
  font-size: 1.1rem;
  table-layout: auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding: 15px;
}

.unsortedTable-header {
  border-bottom: 2px solid #e0e0e0;
}
