.pd-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.pd-textholder {
  width: 130px;
  min-width: 130px;
  padding: 2vh;
  margin-top: 1.5vh;
  font-weight: 500;
  text-align: center;
  border-radius: 3px;
  color: white;
  background: rgb(43, 105, 175);
}
.pd-ans {
  padding: 1vh 2vh;
  margin: 1.5vh;
  margin-bottom: 0;
  min-height: 5vh;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  border-radius: 3px;
  font-weight: 500;
  color: rgb(3, 2, 49);
  background: rgb(180, 209, 248);
}
@media screen and (max-width: 1000px) {
  .pd-row {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .pd-textholder {
    width: 90%;
    text-align: start;
  }
  .pd-ans {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .pd-textholder {
    width: 90%;
    font-size: 13px;
  }
  .pd-ans {
    width: 90%;
    font-size: 13px;
  }
}
