
#add-user .add-user-form .warning-container {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left:12px;
}
#add-user .add-user-form .warning-container .warning{
    color:red;
    padding-left: 12px;
    padding-bottom: 5px;
    font-weight: 300;
}
.existing-arz{
    color:red;
    padding-top: 10px;
    padding-left:12px;
    padding-bottom: 5px;
}
.existingAreas, .existingZones{
    padding-left: 12px;
    padding-bottom: 10px;
}