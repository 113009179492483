#tally-book .project-details{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#tally-book .daily-progress-form{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#tally-book .daily-progress-form .field{
    padding-right: 20px;
}
#tally-book .machinery-user-form{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#tally-book .material-user-form{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
@media screen and (max-width:650px){
    #tally-book .project-details{
        grid-template-columns: 1fr;
    }
    
    #tally-book .daily-progress-form{
        grid-template-columns: 1fr;
    }
}