.aa-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50vh;
}
.aa-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 50vh;
}
.aa-field {
  max-width: 400px;
  
}
.aa-box {
  width: 76%;
  min-height: 20vh;
  border-radius: 4px;
  background: rgb(207, 207, 207);
  margin: 2vh 0;
  padding: 2vh 2%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}
.aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
}
.aa-small-highlight {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
  border: solid rgb(170, 255, 130) 2px;
  background: #28a745;
}
.aa-align-row {
  display: flex;
  flex-direction: row;
  margin: 0.5vh;
  height: 5vh;
  padding: 1vh 0;
  align-items: center;
}

.zone-align {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.zone-align .aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}
@media screen and (max-width: 950px) {
  .aa-row {
    flex-direction: column;
    min-height: 20vh;
  }
  .aa-box {
    width: 90%;
  }
}
