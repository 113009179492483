.main-ims {
  min-height: 100vh;
  width: 100%;
  background-color: #e9e9e9;
}

.ims-godown-holder {
  background-color: white;
  border: 3px solid #1abc9c;
  width: 100%;
  min-height: 200px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 2;
  padding: 20px;
  box-sizing: border-box;
}

.ims-godown-holder-tabs {
  margin-left: 20px;
  margin-bottom: -2px;
}
