.showcause {
  display: flex;
  flex-direction: column;
  margin: 3vh 3%;
  padding: 3vh 3%;
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  width: 88%;
  min-height: 150vh;
  flex-grow: 1;
  font-size: 17px;
  font-weight: 400;
  align-items: center;
}
.jc-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1vh 0;
  font-size: 20px;
  min-height: 30vh;
  color: blak;
  align-items: flex-start;
  justify-content: flex-start;
}
.jc-p {
  font-size: 20px;
}
.sc-2 {
  text-decoration: underline;
  margin: 3vh;
}
.sc-lh {
  height: 14vh;
  width: auto;
}
.sc-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}
.sc-1 {
  width: 100%;
  margin: 0.5vh 0;
  display: flex;
  font-weight: 500;
  min-height: 5vh;
  font-size: 16px;
  align-items: center;
  padding: 2vh 0;
}
.sc-11 {
  justify-content: space-between;
}
.datediv {
  display: flex;
  align-items: center;
}
.lh-forms {
  width: 100%;
  height: auto;
}
.sc-3 {
  width: 100%;
  height: 1vh;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  display: flex;
  margin-bottom: 3vh;
  align-items: center;
  justify-content: space-between;
}
.sc-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 7vh;
  font-size: 20px;
  justify-content: space-between;
}
.sc-5 {
  width: 94%;
  margin: 0.5vh 1vh;
  display: flex;
  flex-direction: column;
  min-height: 5vh;
  font-size: 16px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2vh 3%;
}
.sc-5 p {
  margin: 0.5vh 0;
}
@media screen and (max-width: 1000px) {
  .sc-lh {
    height: 10vh;
    width: auto;
  }
  .showcause {
    font-size: 14px;
  }
  .sc-1 {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .sc-col {
    font-size: 16px;
  }
  .sc-5 {
    font-size: 14px;
  }
  .show-cause-text {
    width: 90%;
  }
  .sc-button Button {
    margin-top: 4vh;
  }
  .datediv {
    width: 99.5%;
    margin-left: -2%;
  }
}
@media screen and (max-width: 600px) {
  .sc-lh {
    height: 7vh;
    width: auto;
  }
  .showcause {
    font-size: 14px;
    min-height: 120vh;
    /* margin:1vh 2%; */
    margin: 0;
    padding: 1vh 2%;
    width: 96%;
  }
  .sc-1 {
    font-size: 14px;
  }
  .sc-col {
    font-size: 14px;
    height: 5vh;
  }
  .sc-5 {
    font-size: 14px;
  }
  .sc-3 {
    height: 0.3vh;
    margin-bottom: 1vh;
  }
}
